@use 'sass:map';
@use '../config';

// Fonts

body {
  font-family: map.get(config.$kq-fonts, 'sans-nordnordost');
}

@font-face {
  font-family: 'GT-Ultra-Standard';
  src: url('../fonts/GT-Ultra-Standard-Light.woff2') format('woff2'),
    url('../fonts/GT-Ultra-Standard-Light.woff') format('woff'),
    url('../fonts/GT-Ultra-Standard-Light.ttf') format('truetype');
  font-weight: normal;
}

@font-face {
  font-family: 'GT-Ultra-Standard';
  src: url('../fonts/GT-Ultra-Standard-Bold.woff2') format('woff2'),
    url('../fonts/GT-Ultra-Standard-Bold.woff') format('woff'),
    url('../fonts/GT-Ultra-Standard-Bold.ttf') format('truetype');
  font-weight: bold;
}
